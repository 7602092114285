import 'slick-carousel';

jQuery(function($){

  $(window).on('scroll', function () {
    if ($(window).scrollTop() > $('.l-first-view-box').height()) {
      $('header').removeClass('is-first');
      $('header').addClass('is-scroll');
      $('.l-header__logotype__w').addClass('d-none');
      $('.l-header__logotype__b').removeClass('d-none');
    } else {
      $('header').addClass('is-first');
      $('header').removeClass('is-scroll');
      $('.l-header__logotype__w').removeClass('d-none');
      $('.l-header__logotype__b').addClass('d-none');
    }
  });

  $(document).ready(function () {
    $('.l-first-view-box__slider').slick({
      fade: true,
      arrows: false,
      dots: false,
      autoplay: true,
      autoplaySpeed: 0,
      speed: 10000,
      slidesToShow: 1,
      slidesToScroll: 1,
    });
  });

  $('.l-customers-voice-box__card.is-1').click(function () {
    $('.l-customers-voice-box__modal').addClass('is-active');
    $('.l-customers-voice-box__modal__content.is-1').addClass('is-active');
    $('body').addClass('is-static');
  });

  $('.l-customers-voice-box__card.is-2').click(function () {
    $('.l-customers-voice-box__modal').addClass('is-active');
    $('.l-customers-voice-box__modal__content.is-2').addClass('is-active');
    $('body').addClass('is-static');
  });

  $('.l-customers-voice-box__card.is-3').click(function () {
    $('.l-customers-voice-box__modal').addClass('is-active');
    $('.l-customers-voice-box__modal__content.is-3').addClass('is-active');
    $('body').addClass('is-static');
  });

  $('.l-customers-voice-box__modal__close').click(function () {
    $('.l-customers-voice-box__modal').removeClass('is-active');
    $('.l-customers-voice-box__modal__content.is-1').removeClass('is-active');
    $('.l-customers-voice-box__modal__content.is-2').removeClass('is-active');
    $('.l-customers-voice-box__modal__content.is-3').removeClass('is-active');
    $('body').removeClass('is-static');
  });

  $('.l-customers-voice-box__modal').click(function () {
    $('.l-customers-voice-box__modal').removeClass('is-active');
    $('.l-customers-voice-box__modal__content.is-1').removeClass('is-active');
    $('.l-customers-voice-box__modal__content.is-2').removeClass('is-active');
    $('.l-customers-voice-box__modal__content.is-3').removeClass('is-active');
    $('body').removeClass('is-static');
  });

});
